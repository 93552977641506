* {
  margin: 0;
  padding: 0;
  color: #fff;
}

html {
  background: #000;
}

#root {
  overflow-x: hidden;
}

.search-list li {
}

.search-list li:nth-child(odd) {
  background: #1e1e1e;
}

.search-list li p {
  margin: 4px 0 0 8px;
}

.flex-box {
  display: flex;
}

.flex-box div {
  margin: 0 8px;
}

.flex-1 {
  flex: 1;
}

.subject {
  line-height: 60px;
  width: 84px;
}

ul li {
  list-style: none !important;
}

h4 {
  margin: 0;
}

.scale-control-box {
  width: 48px;
  text-align: -webkit-center;
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 14px;
}

.scale-control-box button {
  margin: 0;
  padding: 4px;
  width: 46px;
  margin: 8px 0;
}

#TMap {
  height: 50vh;
  width: 100%;
  margin: 0 7px;
}

#TMap .loading {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 300px;
  text-align: center;
}

#marker {
  width: 50px;
  height: 50px;
  border: 1px solid red;
}

.rotationBtn button {
  width: 32px;
  padding: 0;
}

#TMap .ol-custom-overviewmap,
#TMap .ol-custom-overviewmap.ol-uncollapsible {
  bottom: auto;
  left: auto;
  right: 10px;
  top: 4px;
}

#TMap .ol-custom-overviewmap:not(.ol-collapsed) {
  border: 2px solid #000;
}

#TMap .ol-custom-overviewmap .ol-overviewmap-map {
  border: none;
  width: 25vw;
  max-width: 300px;
  margin: 0;
}

#TMap .ol-custom-overviewmap .ol-overviewmap-box {
  border: 2px solid red;
}

#TMap .ol-custom-overviewmap:not(.ol-collapsed) button {
  bottom: auto;
  left: auto;
  right: 1px;
  top: 1px;
}

#TMap .ol-rotate {
  top: 116px;
  left: 10px;
  display: table;
}

#TMap .ol-full-screen {
  top: 76px;
  left: 10px;
  display: table;
}

.full #TMap {
  position: fixed;
  height: 100vh !important;
  background: #fff;
  top: 0;
}

.btn-small {
  width: 32px;
  height: 32px;
  padding: 0;
}

#TMap-wrap {
  position: relative;
}

.full {
  position: initial !important;
}

#TMap-wrap .normalSizeBtn {
  display: none;

  padding: 0;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 60px;
}

.full .normalSizeBtn {
  display: block !important;
}

#TMap-wrap .fullSizeBtn {
  display: block;

  padding: 0;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 60px;
}

.full .fullSizeBtn {
  display: none !important;
}
